.await-screen-creating {
    padding-top: 50px;
}

.await-screen-creating .message-from-developer {
    border: 1px solid gray;
    border-radius: 5px;
    background-color: gray;
    color: white;
    padding: 15px;
    margin: 15px;
    animation: append-animate .3s linear;
    position: relative;
    padding-bottom: 25px;
}

.await-screen-creating .message-from-developer .time {
    position: absolute;
    right: 10px;
    bottom: 5px;
}


@keyframes append-animate {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}