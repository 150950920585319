#virtual_developer_plugin {
    position: absolute;
    top: 0px;
    left: 0px;
}

#virtual_developer_plugin .App {
    border: 1px solid rgba(0,0,0,.87);
    border-radius: 10px;
    width: 200px;
    height: 400px;
    background-color: white;
}

#virtual_developer_plugin .App.with-execute-btn {
    height: 430px;
}

#virtual_developer_plugin .App.with-file {
    height: 426px;
}

#virtual_developer_plugin .App.with-execute-btn.with-file {
    height: 459px;
}

#virtual_developer_plugin .App .header {
    border-bottom: 1px solid rgba(0,0,0,.87);
    position: relative;
    height: 40px;
    padding-left: 40px;
    padding-right: 30px;
    font-size: 13px;
    line-height: 12px;
    padding-top: 6px;
}

#virtual_developer_plugin .App .header .virtual-programmer-image {
    width: 28px;
    position: absolute;
    left: 5px;
    top: 5px;
    cursor: pointer;
}
#virtual_developer_plugin .App .header .help-icon {
    position: absolute !important;
    right: 3px;
    top: 9px;
}

.virtual-developer-plugin-help-popup-content {
    width: 180px;
}

.virtual-developer-plugin-help-popup-content .help-examples {
    height: 186px;
    overflow-y: auto;
}

.virtual-developer-plugin-help-popup-content .help-examples .help-example-item{
    background-color: chocolate;
    color: white;
    padding: 3px 5px;
    border-radius: 5px;
    margin: 2px;
    display: inline-block;
    cursor: pointer;
}

.virtual-developer-plugin-help-popup-content .help-examples .loading-help-examples-container {
    text-align: center;
    padding-top: 30px;
}

.virtual-developer-plugin-help-popup-content .ok-btn-container {
    text-align: right;
}

#virtual_developer_plugin .App .content {
    padding: 15px;
    height: 270px;
    overflow-y: auto;
    position: relative;
}

#virtual_developer_plugin .App .content .loading-chat-logs-container {
    text-align: center;
    padding-top: 50px;
}

#virtual_developer_plugin .App .content .init-help-message {
    color: grey;
}

#virtual_developer_plugin .App .content .message-container {
    position: relative;
}

#virtual_developer_plugin .App .content .message-container .message {
    background-color: #49a68d;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin-bottom: 5px;
    width: fit-content;
    cursor: default;
    max-width: 160px;
    overflow: hidden;
    line-height: 15px;
}

#virtual_developer_plugin .App .content .message-container.from-user .message.fake {
    position: relative;
    opacity: 0;
}

#virtual_developer_plugin .App .content .message-container.from-user .message {
    position: absolute;
    right: 0px;
    background-color: #a280d1;
}

#virtual_developer_plugin .App .content .message-container .message a {
    color: black;
}

#virtual_developer_plugin .App .content .message-container .threeangle {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -15px;
    top: 0px;
    /*border-top: 1px rgb(201 202 202) solid;*/
    overflow: hidden;
}
#virtual_developer_plugin .App .content .message-container.from-user .threeangle {
    right: -15px;
    left: auto;
}

#virtual_developer_plugin .App .content .message-container .threeangle-inner {
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background-color: #49a68d;
    /*border-bottom: 1px rgb(201 202 202) solid;*/
    top: -21px;
    left: 0px;
    position: absolute;
}

#virtual_developer_plugin .App .content .message-container.from-user .threeangle-inner {
    border-bottom: none;
    border-right: 1px rgb(201 202 202) solid;
    background-color: #a280d1;
}


#virtual_developer_plugin .App .footer {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 86px;
    padding: 0px 10px;
}

#virtual_developer_plugin .App.with-execute-btn .footer {
    height: 116px;
}

#virtual_developer_plugin .App.with-file .footer {
    height: 112px;
}

#virtual_developer_plugin .App.with-execute-btn.with-file .footer {
    height: 145px;
}

#virtual_developer_plugin .App .footer .execute-btn {
    width: 100%;
    margin-top: 5px;
}

#virtual_developer_plugin .App .footer .file-container {
    background-color: #cacbcd;
    padding: 3px 3px;
    border-radius: 5px;
    margin-top: 3px;
    color: rgba(0,0,0,.8);
    font-weight: bold;
    position: relative;
}

#virtual_developer_plugin .App .footer .file-container i:first-child {}
#virtual_developer_plugin .App .footer .file-container i:last-child {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 0px;
}

#virtual_developer_plugin .App .footer textarea {
    resize: none;
}

#virtual_developer_plugin .collapsed-app {
    border: 1px solid rgba(0,0,0,.87);
    border-radius: 10px;
    width: 40px;
    height: 40px;
    padding: 5px;
    cursor: pointer;
    background-color: white;
    position: absolute;
}

#virtual_developer_plugin .collapsed-app .virtual-programmer-image {
    width: 28px;
}

#virtual_developer_plugin .virtual-programmer-draggable-element {
    cursor: grab;
}

#virtual_developer_plugin .virtual-programmer-draggable-element.draggabbing{
    cursor: grabbing;
}