.footer .execute-btn {
    width: 100%;
}

.footer .microphone-in-input {
    position: absolute !important;
    top: 3px;
    right: 1px;
    cursor: pointer;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    /*border-top: 2px solid rgba(34,36,38,.15);*/
    /*padding-top: 10px;*/
}

.footer textarea {
    resize: none !important;
    border-radius: 5px 5px 0px 0px !important;
}

.footer button {
    border-radius: 0px 0px 5px 5px !important;
}

.footer .file-container {
    border-radius: 5px;
    border: 1px solid gray;
    padding: 5px 10px;
    margin: 3px 0px;
    position: relative;
    background-color: #f5f5f5;
}

.footer .file-container .close {
    cursor: pointer;
    position: absolute;
    right: 3px;
}

.wrapper-for-chat-body .init-help-message {
    padding-top: 50px;
    color: grey;
}

.wrapper-for-chat-body .message {
    background-color: #49a68d;
    color: white;
    border-radius: 5px;
    padding: 10px 10px;
    margin-bottom: 5px;
    width: fit-content;
    cursor: default;
    max-width: 70%;
    overflow: hidden;
    line-height: 15px;
    padding-right: 30px;
    padding-bottom: 10px;
}
.wrapper-for-chat-body .message p {
    /*margin-bottom: 0px !important;*/
}

.wrapper-for-chat-body .message-container.from-user .message {
    padding-right: 10px;
    padding-bottom: 15px;
}

.wrapper-for-chat-body .message-container {
    position: relative;
}

.wrapper-for-chat-body .message-container.from-user .message.fake {
    position: relative;
    opacity: 0;
}

.wrapper-for-chat-body .message-container.from-user .message {
    position: absolute;
    right: 0px;
    background-color: #a280d1;
}

.wrapper-for-chat-body .message-container .threeangle {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -15px;
    top: 0px;
    /*border-top: 1px rgb(201 202 202) solid;*/
    overflow: hidden;
}

.wrapper-for-chat-body .message-container.from-user .threeangle {
    right: -15px;
    left: auto;
}

.wrapper-for-chat-body .message-container .threeangle-inner {
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background-color: #49a68d;
    /*border-bottom: 1px rgb(201 202 202) solid;*/
    top: -21px;
    left: 0px;
    position: absolute;
}

.wrapper-for-chat-body .message-inner a {
    color: black;
    text-decoration: underline;
}

.wrapper-for-chat-body .message-inner .files-container {
    padding-top: 5px;
}

.wrapper-for-chat-body .message-inner .files-container .file {
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    color: black;
    margin-bottom: 5px;
    cursor: pointer;
}

.wrapper-for-chat-body .message-container.from-user .threeangle-inner {
    border-bottom: none;
    border-right: 1px rgb(201 202 202) solid;
    background-color: #a280d1;
}
.wrapper-for-chat-body .message-inner {
    position: relative;
}

.wrapper-for-chat-body .message-inner .time {
    position: absolute;
    right: -28px;
    bottom: -11px;
    color: #e7e7e7;
    font-size: 9px;
}

.wrapper-for-chat-body .message-container.from-user .time {
    right: -7px;
    bottom: -15px;
}

.wrapper-for-chat-body .message-inner .circle-create-message {
    display: inline-block;    
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: white;
    margin-right: 5px;
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
}

.wrapper-for-chat-body .message-inner .circle-create-message:nth-child(1) {
    animation-name: blackWhiteFade1;
}
.wrapper-for-chat-body .message-inner .circle-create-message:nth-child(2) {
    animation-name: blackWhiteFade2;
}
.wrapper-for-chat-body .message-inner .circle-create-message:nth-child(3) {
    animation-name: blackWhiteFade3;
}

@keyframes blackWhiteFade1 {
    0% { background-color: gray; }
    50% { background-color: white; }
    100% { background-color: white; }
}

@keyframes blackWhiteFade2 {
    0% { background-color: white; }
    50% { background-color: gray; }
    100% { background-color: white; }
}

@keyframes blackWhiteFade3 {
    0% { background-color: white; }
    50% { background-color: white; }
    100% { background-color: gray; }
}

.wrapper-for-chat-body .virtual-developer-in-message {
    position: absolute;
    width: 20px;
    right: -27px;
    top: -7px;
}

.wrapper-for-chat-body #virtual-developer-chat-body {
    padding-top: 20px;
}

.wrapper-for-chat-body {
    overflow: auto;
}